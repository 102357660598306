<template>
	<div>
		<div class="toolBar mBottom10">
			<el-select size="small" v-model="appId" clearable placeholder="请选择" @change="getQrCodeSceneList">
                <el-option
                    v-for="item in wxList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
            </el-select>
			<el-button plain size='small' class='fr' @click="getQrCodeSceneList">刷新</el-button>
		</div>
        <el-card>
    		<el-table border height="calc(100vh - 288px)" size="small" :data="tableList" class="w100"  highlight-current-row>
                <el-table-column type="index" width="50" label='序号' align="center"></el-table-column>
                <el-table-column prop="appName" label='公众号名称' align="center"></el-table-column>
                <el-table-column prop="sceneValue" label='永久二维码编号' align="center"></el-table-column>
                <el-table-column prop="type" label='使用场景' align="center" :formatter="formatScene"></el-table-column>
                <el-table-column prop="sceneId" label='使用编号' align="center"></el-table-column>
            </el-table>
        </el-card>
		<div class="toolBar clearfix" style="margin-top: 10px">
            <pagi-nation :page='page' @sizeChange='sizeChange' @currentChange='currentChange'></pagi-nation>
        </div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				tableList: [],
				page: new this.Page(),
                wxList: [],
                appId: null,
			}
		},
		methods: {
			sizeChange(val) {
                this.page.pageSize = val;
                this.page.currentPage = 1;
                // do sth
                this.getQrCodeSceneList()
            },
            currentChange(val) {
                this.page.currentPage = val;
                // do sth
                this.getQrCodeSceneList()
            },
            //获取公众号列表
            async getWxMpCombo () {
                let res = await this.$http.post('/mp/getWxMpCombo', {})
                if (res.state) {
                    this.wxList = res.data
                }
            },
            //获取二维码列表
            async getQrCodeSceneList () {
                let formData = {}
                formData.appId = this.appId
                formData.limit = this.page.pageSize
                formData.offset = (this.page.currentPage - 1) * this.page.pageSize
                let res = await this.$http.post('/mp/getQrCodeSceneList', formData)
                if (res.state) {
                    this.tableList = res.data.list
                    this.page.total = res.data.totalCount
                }
            },
            //format
            formatScene(row, column) {            //格式化场景
                return row.type || typeof(row.type) == "number" ? this.getname('qrCodeScene', row.type): '-';
            },
		},
		computed: {
            
        },
        created () {
        },
        mounted() {
            this.getWxMpCombo()
            this.getQrCodeSceneList()
        }
	};
</script>
<style scoped>
</style>